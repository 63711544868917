import React from 'react'

// import BasicPage from '~components/BasicPage'
import Layout from '~components/Layout'
import Typography from '~components/Typography'
import theme from '~styles/theme'
import Quote from '~components/Quote'
import { NAVIGATION } from '~utils/constants'

import Img from './Img'
import Content from './Content'
import QuoteText from './QuoteText'

const metaTags = {
  title: 'Sport- und Ernährungscoach in Zürich',
  description:
    'Ich heisse Tania und bin Personal Trainerin in Zürich. Als Bewegungswissenschaftlerin kenne ich alle Tricks für ein effektives Training.',
  slug: NAVIGATION.ABOUT.ABOUT,
  image: {
    url: 'PageAbout/HeroAbout_01--large',
    alt: 'Tania im Engadin, die am Ufer des Silvaplanersees steht',
  },
}

const hero = {
  pictureData: {
    path: 'PageAbout',
    alt: 'Tania im Engadin, die am Ufer des Silvaplanersees steht',
    fallback: {
      name: 'HeroAbout_01--large',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1ODUxIDMyOTEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiMyNzY2NzEiIGQ9Ik0wIDBoNTg1MXYzMjY4SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS40IDExLjQpIHNjYWxlKDIyLjg1NTQ3KSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iIzQ2YzBjMyIgY3g9Ijc0IiBjeT0iMTI5IiByeD0iNjciIHJ5PSI1OSIvPjxlbGxpcHNlIGZpbGw9IiMwNTExMWUiIGN4PSIyNDgiIGN5PSI0MiIgcng9IjEwMiIgcnk9IjMxIi8+PHBhdGggZmlsbD0iIzczM2UzNSIgZD0iTTEzNyA0NWgzNnY5OGgtMzZ6Ii8+PGVsbGlwc2UgZmlsbD0iIzM1YjZiNCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMjMuMTA1MTggMjEuNzczMjIgLTI0LjY2OTM3IC0yNi4xNzg1IDIwNS43IDEyOC43KSIvPjxlbGxpcHNlIGZpbGw9IiMwNTM1NTAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTIuNDM0MzUgMzAuOTgyMzkgLTEwNC45MDQ2OCAtOC4yNDI1OCA0MC43IDQwLjMpIi8+PHBhdGggZmlsbD0iI2FhYjljYiIgZD0iTTE2Ny42IDkzLjdsLTIyLS40LjgtNDMgMjIgLjR6Ii8+PHBhdGggZD0iTTE3MS43IDk0LjdsMS41IDI5LTMxIDEuNi0xLjQtMjl6Ii8+PGVsbGlwc2UgZmlsbD0iIzUwNzM4NCIgY3g9IjE4NiIgY3k9IjExIiByeD0iNDQiIHJ5PSIxOCIvPjwvZz48L3N2Zz4=',
    },
    breakpoints: {
      breakpointSmall: {
        name: 'HeroAbout_01--small',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDUzIDM2NzkiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiMzNTY5NzMiIGQ9Ik0wIDBoMjQ1N3YzNjc5SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjIgNy4yKSBzY2FsZSgxNC4zNzExKSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iIzQ2Y2FjYyIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgzOC4zNTQwNSA0Mi4zMDA2MyAtMzYuNjQ5OSAzMy4yMzA1MyAzNC41IDIyMC4yKSIvPjxwYXRoIGZpbGw9IiMwMzAwMDAiIGQ9Ik0xMzkuMSAxNzMuMWwxLjggNTAtNTIgMS44LTEuOC01MHoiLz48ZWxsaXBzZSBmaWxsPSIjMWYzMTNlIiBjeD0iODciIGN5PSI5NyIgcng9IjE3MCIgcnk9IjQxIi8+PGVsbGlwc2UgZmlsbD0iI2NjYTVhNSIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgtNzEuMiAxNTQuNCAtNy40KSBzY2FsZSgzNi4xMTI3NSAxOS43NzgyMykiLz48ZWxsaXBzZSBmaWxsPSIjOTJiZWQ0IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0uMjgzMDMgLTE2Ljc4NDEzIDgxLjY1ODI4IC0xLjM3NyAyNyAwKSIvPjxlbGxpcHNlIGZpbGw9IiM1YzAwMDAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTkuNjAwMiAtLjE2MTk2IC41NjYzNCAtMzMuNTcwNjUgOTcgMjI3KSIvPjxlbGxpcHNlIGZpbGw9IiMyZWI5YmIiIGN4PSIxNTciIGN5PSIyMjUiIHJ4PSIxOSIgcnk9IjU1Ii8+PHBhdGggZmlsbD0iIzUwMCIgZD0iTTEzNC43IDIwM2w2LjYgNjIuNi0xNCAxLjUtNi42LTYyLjd6Ii8+PC9nPjwvc3ZnPg==',
      },
      breakpointMedium: {
        name: 'HeroAbout_01--medium',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNTk2IDM1OTYiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiMzMzZjNzYiIGQ9Ik0wIDBoMzU5NnYzNTk2SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3IDcpIHNjYWxlKDE0LjA0Njg4KSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iIzQ0YzBjNCIgY3g9IjM0IiBjeT0iMjQyIiByeD0iMTA4IiByeT0iMTA4Ii8+PGVsbGlwc2UgZmlsbD0iIzBiMTcyNCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMTEuOTUxMDUgMzEuNDYxMzYgLTg1LjY1MTI3IC0zMi41MzU4NyAyMTUuOCA5NC4zKSIvPjxlbGxpcHNlIGZpbGw9IiMxMDAwMDAiIGN4PSIxNjgiIGN5PSIyMDMiIHJ4PSIyOCIgcnk9IjMzIi8+PHBhdGggZmlsbD0iI2M3YTFhOSIgZD0iTTE0OSAxMDRoMzh2NjhoLTM4eiIvPjxlbGxpcHNlIGZpbGw9IiMwZjM0NDgiIGN4PSI1OCIgY3k9Ijk0IiByeD0iMTEyIiByeT0iNDMiLz48ZWxsaXBzZSBmaWxsPSIjMzZiOWJhIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC02Ljc5MTU3IC01Ni4yOTQ5NCAzNi4zMDM1MyAtNC4zNzk3NSAyMjkuNiAyMzMuNikiLz48ZWxsaXBzZSBmaWxsPSIjYTZjN2Q5IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC42Mjg2MiAxNC4xNTEwNyAtODAuNjg4IDMuNTg0MzMgNDcuMyAwKSIvPjxwYXRoIGZpbGw9IiM2NzM4MWUiIGQ9Ik0xNDAgMjA2aDU0djUwaC01NHoiLz48L2c+PC9zdmc+',
      },
      breakpointLarge: {
        name: 'HeroAbout_01--large',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1ODUxIDMyOTEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiMyNzY2NzEiIGQ9Ik0wIDBoNTg1MXYzMjY4SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS40IDExLjQpIHNjYWxlKDIyLjg1NTQ3KSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iIzQ2YzBjMyIgY3g9Ijc0IiBjeT0iMTI5IiByeD0iNjciIHJ5PSI1OSIvPjxlbGxpcHNlIGZpbGw9IiMwNTExMWUiIGN4PSIyNDgiIGN5PSI0MiIgcng9IjEwMiIgcnk9IjMxIi8+PHBhdGggZmlsbD0iIzczM2UzNSIgZD0iTTEzNyA0NWgzNnY5OGgtMzZ6Ii8+PGVsbGlwc2UgZmlsbD0iIzM1YjZiNCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMjMuMTA1MTggMjEuNzczMjIgLTI0LjY2OTM3IC0yNi4xNzg1IDIwNS43IDEyOC43KSIvPjxlbGxpcHNlIGZpbGw9IiMwNTM1NTAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTIuNDM0MzUgMzAuOTgyMzkgLTEwNC45MDQ2OCAtOC4yNDI1OCA0MC43IDQwLjMpIi8+PHBhdGggZmlsbD0iI2FhYjljYiIgZD0iTTE2Ny42IDkzLjdsLTIyLS40LjgtNDMgMjIgLjR6Ii8+PHBhdGggZD0iTTE3MS43IDk0LjdsMS41IDI5LTMxIDEuNi0xLjQtMjl6Ii8+PGVsbGlwc2UgZmlsbD0iIzUwNzM4NCIgY3g9IjE4NiIgY3k9IjExIiByeD0iNDQiIHJ5PSIxOCIvPjwvZz48L3N2Zz4=',
      },
    },
  },
  textData: {
    header: 'Über mich',
    subHeader: 'Tania Ferreira',
  },
}

const content = {
  leadText:
    'Hallo, ich bin Tania und bin schon seit Jahren in der Gesundheits- und Sportbranche tätig.',
  paragraph1:
    'Als deine Personal Trainerin kann ich dir helfen, deine verlorene Motivation zu finden oder mit deinem neuen Lifestyle-Projekt anzufangen. Wäre es nicht grossartig, sich fit und energisch zu fühlen und dem eigenen Körper die verdiente Aufmerksamkeit zu geben? Denn schlussendlich hast du nur einen Körper, pass auf ihn auf und behalte ihn fit.\n\nFür mich ist die allgemeine Gesundheit meiner Kunden wichtig: Ich schaue immer auf das ganze Bild und sorge dafür, dass du dich entwickelst und nicht gestresst bist.',
  paragraph2:
    'Es dreht sich vieles um Gesundheit und Sport – Wie essen und wie den Körper bewegen. Genau aus diesem Grund bin ich hier. Ich zeige dir wie du gesund bleibst und einen positiven Lebensstil hast. Das Wissen dazu, habe ich mir mit meinem Studium in Bewegungswissenschaften und Sport an der ETH Zürich, dem anschliessenden Master in Sportphysiologie und Weiterbildungen im Bereich Ernährung angeeignet. Dazu habe ich viele Erfahrungen als Personal Trainerin und Sportlehrerin gesammelt. Wobei, die wertvollsten Erfahrungen habe ich an mir selbst gemacht.',
  paragraph3:
    'Es geht darum die beste Version von dir selbst zu sein. Fördere und bewege deinen Körper und du wirst staunen was du alles erreichen kannst. Gesundheit und Sport reduzieren den Stress, bauen dich auf und bringen mehr Energie in deinen Alltag. Das Ziel ist Gleichgewicht ins Leben zu bringen, Freude an der Bewegung und eine gesunde Ernährung zu haben. Sport soll Spass machen und nicht ein Muss sein. Ich möchte dir zeigen stolz auf deinen Körper zu sein und dich auf dem Weg zu einem gesünderen Lebensstil begleiten.',
  quote1:
    'Aufgewachsen im wunderschönen Engadin und für mein Studium nach Zürich gekommen. Nun sind schon 10 Jahren vorbei und Zürich ist zu meiner zweiten Heimat geworden.',
  quote2: 'Wenn dir etwas wichtig ist, gibt es kein „ABER“',
  quote3:
    'Meine Leidenschaft:\nTriathlon, Langlauf, Krafttraining, Kaffee und natürlich meine Freude am Sport weiterzugeben.',
  imageData: {
    alt: 'Tania fährt mit dem Canyon Rennrad',
    path: 'PageAbout',
    name: 'Bike_01',
    placeholder:
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDg1IDM0NzkiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM4MDg1NmMiIGQ9Ik0wIDBoMjQ4NnYzNDc5SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjggNi44KSBzY2FsZSgxMy41ODk4NCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNmZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMS40NTAwMSAtNTUuMTI5MjggMTY2LjcyNjEgNC4zODUyNCA4Ni41IDEuNSkiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC05Mi4yNjY3NSAtOC40NTM5OSA1LjQ4MjczIC01OS44Mzg0NyA1Ny4zIDEyMi41KSIvPjxlbGxpcHNlIGZpbGw9IiNmZmZmOTQiIGN4PSIyMSIgY3k9IjI1NSIgcng9IjMwIiByeT0iODIiLz48ZWxsaXBzZSBmaWxsPSIjZmJmZmZmIiBjeD0iMTExIiBjeT0iMTUiIHJ4PSIxODIiIHJ5PSIzMiIvPjxlbGxpcHNlIGZpbGw9IiM0NzNkMTUiIGN4PSIxMzQiIGN5PSIxNTciIHJ4PSI0NiIgcnk9Ijc0Ii8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTgzLjIgMjcxbDI0LjQtNDEgMjAuNC02IDI4IDQ2Ljl6Ii8+PGVsbGlwc2UgY3g9IjE2IiBjeT0iMTMwIiByeD0iNDUiIHJ5PSI0NSIvPjxlbGxpcHNlIGZpbGw9IiM3Yjc5NWEiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoNTYuMSAtMjMuNSA4NC41KSBzY2FsZSgyMS41Nzk0NyAyNy42OTY4MikiLz48L2c+PC9zdmc+',
  },
}

const PageAbout = ({ location }) => (
  <Layout
    hero={hero}
    pictureData={hero.pictureData}
    metaTags={metaTags}
    pathname={location.pathname}
  >
    <Content>
      <Typography limit align="center">
        {content.leadText}
      </Typography>
      <Typography limit preWrap>
        {content.paragraph1}
      </Typography>
      <Quote
        position="end"
        width="75%"
        background={theme.palette.secondary.main}
      >
        <QuoteText position="right">{content.quote1}</QuoteText>
      </Quote>
      <Img limit imageData={content.imageData} />
      <Typography limit preWrap>
        {content.paragraph2}
      </Typography>
      <Quote
        position="start"
        width="50%"
        background={theme.palette.secondary.main}
      >
        <QuoteText position="left">{content.quote2}</QuoteText>
      </Quote>
      <Typography limit preWrap>
        {content.paragraph3}
      </Typography>
      <Quote
        position="end"
        width="70%"
        background={theme.palette.secondary.main}
      >
        <QuoteText preWrap position="right">
          {content.quote3}
        </QuoteText>
      </Quote>
    </Content>
  </Layout>
)

export default PageAbout
